import React, { createContext, useEffect, useState } from 'react';
import auth0 from 'auth0-js';
import AUTH0_CONSTANTS from '../constants/AUTH0.gen.json';
import { useGetSubscriptionMembership } from '../data-client/subscription-membership';
import {
  logout as analyticsLogout,
  login as analyticsLogin,
  identify as analyticsIdentify,
} from '../data-client/analytics';

const AUTH0 = AUTH0_CONSTANTS[process.env.REACT_APP_RUNTIME];

const auth0Options = {
  domain: AUTH0.DOMAIN,
  clientID: AUTH0.REACT_APP_AUTH0_CLIENT_ID,
  responseType: 'token',
  audience: AUTH0.AUDIENCE,
  redirectUri: `${window.location.origin}/authenticate`,
};

const Auth0Context = createContext();
export default Auth0Context;

const ERROR_MAP = {
  invalid_request: 'An email address and password are required.',
  access_denied: 'Incorrect email or password',
  email_required: 'An email must be given in order to send a passward change email.',
  generic:
    'An unkown occurred. Please wait a few minutes and try again. If the issue persist please contact your venice rep.', //never seen it get here, just a safety net
};

export function Auth0Provider({ children }) {
  const [errors, setErrors] = useState();
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isAttempting, setIsAttempting] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('scale_music_auth0_token') || null);
  const loggedInSubscriptionMembershipQuery = useGetSubscriptionMembership(token); //Historically it only
  // accepted

  if (!localStorage.getItem('login_redirect_url')) {
    if (window.pathname !== '/login') {
      localStorage.setItem('login_redirect_url', window.location.pathname + window.location.search);
    } else {
      localStorage.setItem('login_redirect_url', '/homepage');
    }
  }

  const postLoginUrl = localStorage.getItem('login_redirect_url');

  const getAccessTokenFromCurrentSSOSession = () => {
    return new Promise(resolve => {
      const webAuth = new auth0.WebAuth(auth0Options);

      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          resolve(null);
          return;
        }
        resolve(authResult.accessToken);
      });
    });
  };

  useEffect(() => {
    const newUrl = window.location.pathname + window.location.search;
    if (newUrl !== postLoginUrl) {
      localStorage.setItem('login_redirect_url', newUrl);
    }
  }, [postLoginUrl]);

  const { data, isLoading: membershipLoading } = loggedInSubscriptionMembershipQuery;

  useEffect(() => {
    const iframe = document.getElementById('apply-token');
    iframe.onload = () => {
      const authEvent = { detail: token };
      iframe.contentWindow.postMessage(authEvent, `${process.env.REACT_APP_VENICE_URL}`);
    };
  }, [token]);

  useEffect(() => {
    const loginIfAuthenticatedWithSSO = async () => {
      const queryParams = new URLSearchParams(window.location.hash.replace('#', '?'));

      // If user is not authenticated or logged in via the sign in modal
      if (!token && !queryParams.get('access_token')) {
        const accessToken = await getAccessTokenFromCurrentSSOSession();

        if (accessToken) {
          setToken(accessToken);
          localStorage.setItem('scale_music_auth0_token', accessToken);
        }
      }
    };
    loginIfAuthenticatedWithSSO();
    // eslint-disable-next-line
  }, []);

  function login({ email, password }) {
    setIsAttempting(true);

    const webAuth = new auth0.WebAuth(auth0Options);

    /**
     * Called when a login is successful (before navigation).
     * Must call the `done` function to continue the login process.
     *
     * @param {() => void} done - Call this function to continue the login process.
     * @return {void}
     */
    const loginSuccessCallback = done => {
      analyticsLogin(email);
      analyticsIdentify(email);

      return done();
    };

    const loginFailedCallback = results => {
      setIsAttempting(false);

      if (results?.error) {
        return setErrors(ERROR_MAP[results.code]);
      }

      setErrors(null);
    };

    return webAuth.login(
      {
        username: email,
        password,
        onRedirecting: loginSuccessCallback,
      },
      loginFailedCallback
    );
  }

  function logout(email) {
    const webAuth = new auth0.WebAuth(auth0Options);

    window.heap && window.heap.resetIdentity();
    analyticsLogout(email);

    setIsAttempting(true);
    webAuth.logout({ returnTo: `${window.location.origin}/authenticate#logout` });
  }

  async function requestPasswordReset({ email }) {
    if (!email) {
      return setErrors(ERROR_MAP.email_required);
    }
    setIsAttempting(true);
    setIsResettingPassword(true);
    const webAuth = new auth0.WebAuth({
      domain: AUTH0.DOMAIN,
      clientID: AUTH0.REACT_APP_AUTH0_CLIENT_ID,
    });

    webAuth.changePassword({ email, connection: 'Username-Password-Authentication' }, results => {
      if (results?.error) {
        return setErrors(ERROR_MAP[results.code]);
      }
      setErrors(null);
      setIsRequestSent(true);
      setIsAttempting(false);
      setIsResettingPassword(false);
    });
  }

  return (
    <Auth0Context.Provider
      value={{
        login,
        logout,
        requestPasswordReset,
        token,
        loginError: errors,
        membershipLoading,
        isAttempting: isAttempting || membershipLoading,
        isPasswordRequestSent: isRequestSent,
        isResettingPassword,
        setIsResettingPassword,
        setToken,
        user: data?.user,
        loggedInSubscriptionMembershipQuery,
        postLoginUrl,
      }}>
      {children}
      <iframe
        id="apply-token"
        title="apply-token"
        // if REACT_APP_VENICE_URL isn't in .env it will infinity loop
        src={`${process.env.REACT_APP_VENICE_URL}/apply-token.html`}
        style={{ position: 'absolute', width: 0, height: 0, border: 0, display: 'none !important' }}></iframe>
    </Auth0Context.Provider>
  );
}
